
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import ModalWrapper from '@/modules/common/components/modal-wrapper.vue';
import ParityService, { ParityServiceS } from '@/modules/cars/modules/parity/parity.service';
import moment from 'moment';
import Screenshot from './screenshot.vue';
import PopupDifTab from './popup-dif-tab.vue';
import ParityFiltersService, { ParityFiltersServiceS } from '../../parity-filters.service';

@Component({
    components: {
        ModalWrapper,
        Screenshot,
        PopupDifTab,
    },
})
export default class ParityComparePopup extends Vue {
    @inject(ParityServiceS) private parityService!: ParityService;
    @inject(ParityFiltersServiceS) private parityFiltersService!: ParityFiltersService;

    maxedScreenshotTitle: string | null = null;

    handleMinMaxScreenshot(title: string | null) {
        this.maxedScreenshotTitle = title;
    }

    get count() {
        return this.parityService.tableCount;
    }

    get currentDocument() {
        if (this.parityService.currentDocument === null) {
            this.$router.push({ name: 'parity' });
            return null;
        }

        return this.parityService.currentDocument;
    }

    get isAvailable() {
        if (this.parityService.currentDocument === null) {
            return false;
        }

        switch (this.parityService.currentDocument.latestBml.bmlClassification) {
            case 'B':
            case 'M':
            case 'L':
                return true;
            default:
                return false;
        }
    }

    get bmlAssessment() {
        if (this.parityService.currentDocument === null) {
            return null;
        }

        switch (this.parityService.currentDocument.latestBml.bmlClassification) {
            case 'M':
                return 'in parity with';
            case 'B':
                return 'beat';
            case 'L':
                return 'lost to';
            default:
                return `not available for sale at ${this.parityService.currentDocument.latestBml.brandData.providerName}`;
        }
    }

    date(date: string | null) {
        if (!date) {
            return '';
        }
        const parsedDate = new Date(date);
        return moment(date).utc().format('DD-MMM-YYYY | HH:mm');
    }
}
