
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import ParityComparePopup from '../components/compare-popup/parity-compare-popup.vue';

@Component({
    components: { ParityComparePopup },
})
export default class DayRateModalPage extends Vue {
    @inject(UserServiceS) userService!: UserService;

    mounted() {
        // if (!this.userService.currentCompany) {
        //     return;
        // }
        // if (this.userService.currentCompany.toLowerCase() === 'rentalcars') {
        //     this.$router.push('/');
        // }
    }
}
