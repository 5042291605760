
import { Component, Vue, Prop } from 'vue-property-decorator';
import moment from 'moment';
import BrokerInfo from './broker-info.vue';
import ParityDocumentItemModel from '../../models/parity-document-item.model';

@Component({
    components: {
        BrokerInfo,
    },
})
export default class PopupDifTab extends Vue {
    @Prop({
        type: Object,
        required: true,
    })
    currentDocument!: ParityDocumentItemModel;

    date(date: string) {
        const parsedDate = new Date(date);
        return `${parsedDate.getDate()}-${parsedDate.getMonth() + 1}-${parsedDate.getFullYear()}`;
    }

    getColor(bmlClassification: 'B' | 'M' | 'L') {
        switch (bmlClassification) {
            case 'B':
                return 'yellow';
            case 'M':
                return 'green';
            case 'L':
                return 'red';
            default:
                return 'grey';
        }
    }

    get pickUpDate() {
        return moment(this.currentDocument.pickUpDate).format('DD-MM-YYYY');
    }
}
